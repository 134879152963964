<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitNewUser">
      <div class="form-group">
        <label for="newUser_email">E-mail</label>
        <input
            id="newUser_email"
            v-model="newUser.email"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.email.$error }"
        />
        <div v-if="submitted && $v.newUser.email.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.email.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newUser.email.email">{{$t('validatePhrases.invalidEmail')}}</span>
          <span v-if="!$v.newUser.email.maxLength">
            {{$t('validatePhrases.maxLength')}}: 250
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="newUser_username">{{$t('views.users.userName')}}</label>
        <input
            id="newUser_username"
            v-model="newUser.username"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.username.$error }"
        />
        <div v-if="submitted && $v.newUser.username.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.username.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newUser.username.maxLength">
            {{$t('validatePhrases.maxLength')}}: 250
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="newUser_password">{{$t('views.login.password')}}</label>
        <input
            id="newUser_password"
            v-model="newUser.password"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.password.$error }"
        />
        <div v-if="submitted && $v.newUser.password.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.password.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newUser.password.maxLength">
            {{$t('validatePhrases.maxLength')}}: 250
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="newUser_phone_number">Контактный номер телефона</label>
        <input
            id="newUser_phone_number"
            v-model="newUser.phone_number"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.phone_number.$error }"
        />
        <div v-if="submitted && $v.newUser.phone_number.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.phone_number.maxLength">
            {{$t('validatePhrases.maxLength')}}: 25
          </span>
        </div>
      </div>
      <div class="form-group">
        <label>{{$t('views.users.franchise')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.franchiseId.$error }"
            v-model="newUser.franchiseId"
        >
          <option
              v-for="item in franchises"
              :key="item.id"
              :value="item.id"
          >
            {{item.franchise_name}}
          </option>
        </select>
        <div v-if="submitted && $v.newUser.franchiseId.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.franchiseId.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newUser.franchiseId.minValue">
            {{$t('validatePhrases.chooseFranchise')}}
          </span>
        </div>
      </div>
      <div class="form-group">
        <label>{{$t('views.users.legalPerson')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.legalId.$error }"
            v-model="newUser.legalId"
        >
          <option value="0">{{$t('views.users.withoutRefLegal')}}</option>
          <option
              v-for="item in legalPersonsComputed"
              :key="item.id"
              :value="item.id"
          >
            {{item.legal_person_name}}
          </option>
        </select>
        <small class="form-text text-muted">
          {{$t('views.users.whenChooseLegal')}}
        </small>
        <div v-if="submitted && $v.newUser.legalId.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.legalId.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newUser.legalId.minValue">
            {{$t('validatePhrases.chooseLegalPerson')}}
          </span>
        </div>
      </div>
      <div class="form-group">
        <label>{{$t('views.users.bindServicePoint')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newUser.servicePointId.$error }"
            v-model="newUser.servicePointId"
        >
          <option value="0">{{$t('views.users.withoutBind')}}</option>
          <option
              v-for="item in servicesPoints"
              :key="item.id"
              :value="item.id"
          >
            {{item.name}}
          </option>
        </select>
        <div v-if="submitted && $v.newUser.servicePointId.$error" class="invalid-feedback">
          <span v-if="!$v.newUser.servicePointId.required">{{$t('validatePhrases.requiredField')}}</span>
        </div>
      </div>
      <div class="custom-control custom-checkbox mb-3">
        <input v-model="newUser.isMasterFranchise" type="checkbox" id="printerData_isMasterFranchise" class="custom-control-input">
        <label for="printerData_isMasterFranchise" class="custom-control-label">{{$t('views.users.mainFranchise')}}</label>
      </div>
      <div class="form-group">
        <label>{{$t('views.users.roles')}}</label>
        <SimpleSelectCheckbox
          :data-component="roleList"
          @changeValue="changeRoleSelect"
        ></SimpleSelectCheckbox>
      </div>
      <div class="form-group">
        <label>{{$t('views.users.permissions')}}</label>
        <SimpleSelectCheckbox
            :data-component="permList"
            @changeValue="changePermSelect"
        ></SimpleSelectCheckbox>
      </div>
      <button class="btn btn-sm btn-success" type="submit">{{$t('popularWords.add')}}</button>
    </form>

  </div>
</template>

<script>


// import axios from "axios";
// import { maxValue, minValue, required} from "vuelidate/lib/validators";

import {maxLength, required, email, minValue} from "vuelidate/lib/validators";
import axios from "axios";
import SimpleSelectCheckbox from "@/components/SimpleSelectCheckbox";

export default {
  name: "NewUser",
  components: {SimpleSelectCheckbox},
  data(){
    return {
      newUser: {
        email: '',
        username: '',
        password: '',
        phone_number: '',
        franchiseId: '',
        legalId: '',
        servicePointId: '',
        isMasterFranchise: false,
        roles: [],
        permissions: []
      },
      franchises: [],
      roleList: [],
      permList: [],
      servicesPoints: [],
      submitted: false,
    }
  },
  props: {

  },
  watch: {

  },
  computed: {
    legalPersonsComputed(){
      let result = [];
      if(this.newUser.franchiseId !== '' && this.newUser.franchiseId > 0){
        for(let key in this.legalPersons){
          if(+this.legalPersons[key].franchise_id === +this.newUser.franchiseId){
            result[result.length] = this.legalPersons[key];
          }
        }
      }
      return result;
    }
  },
  validations: {
    newUser: {
      email: { required, maxLength: maxLength(250), email },
      username: { required, maxLength: maxLength(250) },
      password: { required, maxLength: maxLength(250) },
      phone_number: { maxLength: maxLength(25) },
      franchiseId: { required, minValue: minValue(1) },
      legalId: { required, minValue: minValue(0) },
      servicePointId: { required, minValue: minValue(0) }
    }
  },
  methods: {
    submitNewUser(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newUser.$touch();
      if(!this.$v.newUser.$invalid){
        let formData = new FormData();
        formData.append("email", this.newUser.email);
        formData.append("username", this.newUser.username);
        formData.append("password", this.newUser.password);
        formData.append("phone_number", this.newUser.phone_number);
        formData.append("franchise_id", this.newUser.franchiseId);
        formData.append("legal_id", this.newUser.legalId);
        formData.append("service_point_id", this.newUser.servicePointId);
        formData.append("is_master_franchise", this.newUser.isMasterFranchise);
        formData.append("roles", JSON.stringify(this.newUser.roles));
        formData.append("permissions", JSON.stringify(this.newUser.permissions));

        axios
            .post(`/v1/users/create`, formData)
            .then(() => {
              this.$emit('userCreated');
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    changeRoleSelect(val){
      this.newUser.roles = val;
    },
    changePermSelect(val){
      this.newUser.permissions = val;
    },
    preparePermData(val) {
      let result = [];
      for (let key in val) {
        let obj = {};
        obj.id = val[key].name;
        obj.name = val[key].description;
        result.push(obj);
      }
      return result;
    },
    getFranchises(){
      axios
          .get(`/v1/franchise/get-all`)
          .then(resp => {
            this.franchises = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getLegalPersons(){
      axios
        .get(`/v1/legal-person/get-all`)
        .then(resp => {
          this.legalPersons = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    getRoles(){
      axios
          .post(`/v1/role/get-all`)
          .then(resp => {
            this.roleList = this.preparePermData(resp.data.roles);
            this.permList = this.preparePermData(resp.data.perm);
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getServicesPoints(){
      axios
          .post(`/v1/services-points/get-all`)
          .then(resp => {
            this.servicesPoints = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getFranchises();
    this.getRoles();
    this.getLegalPersons();
    this.getServicesPoints();
  }

};
</script>

<style scoped lang="css">

</style>
