<template>
  <div class="s-select-checkbox--container">
    <div class="actions">
      <div @click="selectAll">Выбрать все</div>
      <div @click="unselectAll">Снять все</div>
    </div>
    <div class="items no-select">
      <div
          class="item"
          v-for="item in list"
          :key="item.id"
      >
        <input
            type="checkbox"
            v-model="item.checked"
            @click="toggleCheckService(item.id)"
        >
        <span @click="toggleCheckService(item.id)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleSelectCheckbox",
  data() {
    return {
      list: [],
    };
  },
  props: {
    dataComponent: Array, // Все данные доступные для выбора (id, name)
    reset: Number, // При изменении сбрасывает компонент
    default: Array // Устанавливает выбранные значения из списка dataComponent. На вход [id, id...]
  },
  computed: {},
  watch: {
    dataComponent: function(val){
      this.filterDataCatList(val);
    },
    reset: function(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", false);
      }
      this.emitList();
    },
    default: function(val) {
      for(let keyList in this.list){
        this.$set(this.list[keyList], "checked", false);
      }
      if(val.length){
        for(let keyDef in val){
          for(let keyList in this.list){
            if(this.list[keyList].id == val[keyDef]){
              this.$set(this.list[keyList], "checked", true);
            }
          }
        }
      }
      this.emitList();
    }
  },
  methods: {
    selectAll(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", true);
      }
      this.emitList();
    },
    unselectAll(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", false);
      }
      this.emitList();
    },
    toggleCheckService(itemId){
      for(let item in this.list){
          if(itemId == this.list[item].id){
            this.$set(this.list[item], "checked", !this.list[item].checked);
          }
      }
      this.emitList();
    },
    emitList(){
      let result = [];
      for(let item in this.list){
        if(this.list[item].checked){
          result[result.length] = this.list[item].id;
        }
      }
      this.$emit('changeValue', result);
    },
    filterDataCatList(data){
      this.list = [];
      for(let item in data){
        let obj = {};
        obj.id = data[item].id;
        obj.name = data[item].name;
        obj.checked = false;
        this.list.push(obj);
      }
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
.s-select-checkbox--container{
  .actions{
    text-align: right;
    &>div{
      color: gray;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 5px;
      text-decoration: underline;
      cursor: pointer;
      font-size: 13px;
    }
  }
  .items{
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;

    .item{
      padding: 4px 5px 4px 5px;
      margin: 5px 4px;
      border-radius: 8px;
      background-color: #f1f0f0;

      span{
        display: inline-block;
        margin-left: 7px;
      }
    }
  }
}
</style>
