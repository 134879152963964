<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.users.userList')}}</h4>

            <div class="row">
              <div class="col-lg-12 text-right">
                <div @click="showNewUserPopup" class="btn btn-sm btn-success">
                  <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 mt-3">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>Email</th>
                    <th>{{$t('views.users.name')}}</th>
                    <th>{{$t('views.users.franchise')}}</th>
                    <th>{{$t('views.users.createDate')}}</th>
                    <th>{{$t('views.users.lastLogin')}}</th>
                    <th>{{$t('views.users.actions')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td @click.prevent="editUserShow(item)">{{item.email}}</td>
                    <td @click.prevent="editUserShow(item)">{{item.username}}</td>
                    <td @click.prevent="editUserShow(item)">
                      <span v-if="item.franchise">{{ item.franchise.franchise_name }}</span>
                      <span v-if="!item.franchise" class="text-danger">
                        Франшиза пользователя была удалена. Измените или удалите пользователя
                      </span>
                    </td>
                    <td @click.prevent="editUserShow(item)">{{item.create_datetime}}</td>
                    <td @click.prevent="editUserShow(item)">{{item.last_login}}</td>
                    <td>
                      <div @click.prevent="showDeleteUserPopup(item.id)" class="btn btn-sm btn-danger">
                        <i class="fa fa-times"></i>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="paginator mt-3">
              <paginator
                  :pages="pagesCountPaginator"
                  :now="thisPage"
                  @setPage="setPage"
              ></paginator>
            </div>

          </div>
        </div>
      </div>

      <popup
          :closeButton="newUserPopup.closeButton"
          :show="newUserPopup.show"
          @closePopup="closeNewUserPopup"
      >
        <div slot="header">{{$t('views.users.createUser')}}</div>
        <div slot="body">
          <NewUser
            @userCreated="userCreated"
          ></NewUser>
        </div>
      </popup>

      <popup
          :closeButton="editUserPopup.closeButton"
          :show="editUserPopup.show"
          @closePopup="closeEditUserPopup"
      >
        <div slot="header">{{$t('views.users.editUser')}}</div>
        <div slot="body">
          <EditUser
              :data-user="editUser"
              @updateUser="userUpdated"
          ></EditUser>
        </div>
      </popup>

      <popup
          :closeButton="deleteUserPopup.closeButton"
          :actionButton="deleteUserPopup.actionButton"
          :actionClass="deleteUserPopup.actionClass"
          :show="deleteUserPopup.show"
          @closePopup="closeDeleteUserPopup"
          @actionPopup="deleteUserPopupSubmit"
      >
        <div slot="header">{{$t('views.users.deleteUser')}}</div>
        <div slot="body">
          {{$t('views.users.deleteUserText')}}
        </div>
      </popup>

    </div>
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// import {
//   required,
//   minLength,
//   maxLength
// } from "vuelidate/lib/validators";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";
import NewUser from "@/components/management/users/NewUser";
import EditUser from "@/components/management/users/EditUser";

/**
 * Rbac component
 */
export default {
  components: {
    EditUser,
    NewUser,
    Popup,
    Paginator,
    Layout,
    PageHeader,
  },
  data() {
    return {
      newUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      editUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      deleteUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedUserId: 0,
      editUser: {},
      submitted: false,
      title: this.$t('menuitems.management.list.users'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.users'),
          active: true
        }
      ],
      data: [],
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    closeDeleteUserPopup(){
      this.deleteUserPopup.show = false;
    },
    showDeleteUserPopup(id){
      this.deleteUserPopup.show = true;
      this.deletedUserId = id;
    },
    deleteUserPopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedUserId);
      axios
          .post(`/v1/users/delete-user`, formData)
          .then(() => {
            this.closeDeleteUserPopup();
            this.getData();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editUserShow(obj){
      this.editUser = obj;
      this.editUserPopup.show = true;
    },
    closeEditUserPopup(){
      this.editUserPopup.show = false;
    },
    userCreated(){
      this.closeNewUserPopup();
      this.getData();
    },
    userUpdated(){
      this.closeEditUserPopup();
      this.getData();
    },
    closeNewUserPopup(){
      this.newUserPopup.show = false;
    },
    showNewUserPopup(){
      this.newUserPopup.show = true;
    },
    getData() {
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      axios
          .post(`/v1/users/get-list`, formData)
          .then(resp => {
            this.data = resp.data.data;
            this.pagesCountPaginator = resp.data.pages;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    }
  },
  created() {
    this.getData();
  }
};
</script>
